import React from 'react'

export default function Overview() {
  return (
    <div>
      <section className="st-gray-bg st-about-wrap">
            <div className="st-shape-bg">
                <img src="assets/img/shape/about-bg-shape.svg" alt="shape"/>
            </div>
            <div className="st-height-b20 st-height-lg-b40"></div>
            <div className="container">
                <div className="st-section-heading st-style1">
                    <h2 className="st-section-heading-title">Business Overview</h2>
                   
                </div>
                <div className="st-height-b40 st-height-lg-b40"></div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0.2s">
                        <div className="st-about-img">
                            <div className="st-about-img-in st-dynamic-bg st-bg" style={{ backgroundImage: "url(/assets/img/hero-bg3.jpg)" }}  data-src="assets/img/hero-bg3.jpg" alt="Bluella Agritech">
                           
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-6">
                        <div className="st-vertical-middle">
                            <div className="st-vertical-middle-in">
                                <div className="st-text-block st-style1">
                                   
                                    <div className="st-height-b20 st-height-lg-b20"></div>
                                    <div className="st-text-block-text">
                                        <p>Bluella, a digital Agritech company, is transforming Indian farmers' livelihoods through technology. At every stage of the agricultural value chain, farmers face issues that need to be addressed and to solve these challenges, we are creating hardware, software, and other such advanced services.</p>
                                        <p>To make the farmers’ lives more relaxed, smooth and help them increase yields, we will utilize a combination of consulting and data analytics. </p>
                                        <p> With the support of smart farming solutions, the transformation of Indian agriculture is just around the corner that will make farming simpler along with other daily duties. </p>
                                        <p>To do this, we are building an entire ecosystem that enables seamless communication between farmers, data aggregation for real-time analytics and guidance, farm management tools for capital tracking, and warehouse access for prompt input-goods replenishment. </p>
                                        <p>There is a constant development of new methods for them to use smart farming, such as IoT-enabled sensors and tools to monitor parameters like soil health, humidity, and temperature. </p>
                                    </div>
                                    <div className="st-height-b25 st-height-lg-b25"></div>

                                </div>
                            </div>
                        </div>
                        <div className="st-height-b0 st-height-lg-b30"></div>
                    </div>
                

                    <div className="st-shape4">
                        <img src="assets/img/shape/section_shape.png" alt="shape1"/>
                    </div>
                    <div className="st-height-b20 st-height-lg-b80"></div>
                    <div className="container">
                        <div className="st-section-heading st-style1">
                            <h3 className="st-section-heading-title">Productivity Statistics</h3>
                         
                        </div>
                        <div className="st-height-b20 st-height-lg-b80"></div>
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="row">

                                    <div className="col-lg-6">
                                        <div className="st-funfact st-style1">

                                            <h2 className="st-funfact-number ">20%</h2>
                                            <div className="st-funfact-title">Increase in production</div>
                                        </div>
                                        <div className="st-height-b30 st-height-lg-b30"></div>
                                    </div>
                                  
                                    <div className="col-lg-6">
                                        <div className="st-funfact st-style1">
                                            <h2 className="st-funfact-number">30%</h2>
                                            <div className="st-funfact-title"> Reduction in waste and crop diseases</div>
                                        </div>
                                        <div className="st-height-b30 st-height-lg-b30"></div>
                                    </div>
                                  
                                    <div className="col-lg-6">
                                        <div className="st-funfact st-style1">

                                            <h2 className="st-funfact-number">80%</h2>
                                            <div className="st-funfact-title">Export quality produced</div>
                                        </div>
                                        <div className="st-height-b30 st-height-lg-b30"></div>
                                    </div>
                                  
                                    <div className="col-lg-6">
                                        <div className="st-funfact st-style1">

                                            <h2 className="st-funfact-number">33%</h2>
                                            <div className="st-funfact-title">Increase in farmers income</div>
                                        </div>
                                        <div className="st-height-b30 st-height-lg-b30"></div>
                                    </div>
                                

                                    <div className="col-lg-6">
                                        <div className="st-funfact st-style1">
                                            <h2 className="st-funfact-number">20%</h2>
                                            <div className="st-funfact-title">Decrease in resources utilization</div>
                                        </div>
                                    </div>
                                   

                                    <div className="col-lg-6">
                                        <div className="st-funfact st-style1">
                                            <h2 className="st-funfact-number">30%</h2>
                                            <div className="st-funfact-title"> Reduction in liters of water</div>
                                        </div>
                                        <div className="st-height-b30 st-height-lg-b30"></div>
                                    </div>
                                 

                                </div>
                            </div>
                            <div className="col-xl-6 wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                <div className="st-video-block st-style1 st-zoom">
                                      <div className="st-video-block-img st-zoom-in st-dynamic-bg" style={{ backgroundImage: "url(/assets/img/gallery/p22.png)" }} data-src="assets/img/gallery/p22.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli">
                                      
                                      
                                      
                                      </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="st-height-b20 st-height-lg-b80"></div>
                </div>
            </div>
            <div className="st-height-b20 st-height-lg-b40"></div>
        </section>
       
    </div>
  )
}
