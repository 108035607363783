import React from 'react'

export default function Whatwedo() {
  return (
    <div>
       <section id="whatwedo">
            <div className="st-height-b20 st-height-lg-b80"></div>
            <div className="container">
                <div className="st-section-heading st-style1">
                    <h2 className="st-section-heading-title">What we do?</h2>
                  
                </div>
                <div className="st-height-b20 st-height-lg-b40"></div>
            </div>
            <div className="container">
                <div className="st-tabs st-fade-tabs st-style1">

                    <div className="st-height-b20 st-height-lg-b25"></div>
                    <div className="tab-content">
                        <div id="Crutches" className="st-tab active">
                            <div className="st-imagebox st-style2">
                                <div className="row">

                                    <div className="col-lg-6">
                                        <div className="st-vertical-middle">
                                            <div className="st-vertical-middle-in">
                                                <div className="st-imagebox-info">
                                                    <ul  className="fa-ul">
                                                        <li> <span className="fa-li"><i className="fa fa-check"></i></span>Utilizing data-driven technology, the ERP solution is created for farmers to increase their productivity and efficiency.</li>
                                                        <li> <span className="fa-li"><i className="fa fa-check"></i></span>Apps, Dashboard, Alerts, Analytics & Insights.</li>
                                                        <li> <span className="fa-li"><i className="fa fa-check"></i></span>Connect farmers with thousands of verified crop buyers. </li>
                                                        <li> <span className="fa-li"><i className="fa fa-check"></i></span>Comprehensive financial management based on historical data as well as future insights and trends.</li>
                                                        <li> <span className="fa-li"><i className="fa fa-check"></i></span>Diester recovery insights and forecasts, whenever there is a pest disease or outbreak.</li>

                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-lg-6">
                                        <div className="st-imagebox-img"><img src="assets/img/bg-1.jpeg" alt="Bluella Agritech"/></div>
                                        <div className="st-height-b0 st-height-lg-b30"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
            <div className="st-height-b20 st-height-lg-b80"></div>
        </section>
       
    </div>
  )
}
