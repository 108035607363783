import React from 'react'

export default function About() {
  return (
    <div>
         <section className="st-gray-bg st-about-wrap" id="about">
            <div className="st-shape-bg">
                <img src="assets/img/shape/about-bg-shape.svg" alt="Bluella Agritech"/>
            </div>
            <div className="st-height-b20 st-height-lg-b50"></div>
            <div className="container">
                <div className="st-section-heading st-style1">
                    <h2 className="st-section-heading-title">About Bluella Agritech</h2>
                       <div className="st-height-b40 st-height-lg-b40"></div>
                    <div className="st-section-heading-subtitle">Bluella Agritech - a digital agritech company, is transforming Indian farmers' livelihoods through technology.</div>
                </div>
                <div className="st-height-b20 st-height-lg-b40"></div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="st-vertical-middle">
                            <div className="st-vertical-middle-in">
                                <div className="st-text-block st-style1">
                                   
                                    <div className="st-height-b20 st-height-lg-b20"></div>
                                    <div className="st-text-block-text">
                                        <p>We are a technology company with a vision of bringing smart farming to every Indian farmer. Bluella helps increase farmer's incomes which ultimately improves their lives.</p>
                                        <p>Our mission is to make Indian farmers more profitable by easing their lives and helping them improve yields. We do this with a combination of technology, consulting, and data analysis. </p>
                                        <p>We are changing the future of Indian agriculture with smart farming technologies. We are committed to simplifying farming and using technology to assist farmers in their day-to-day tasks in an easy, cost-effective way. </p>
                                        <p>We are committed to simplifying farming and using technology to assist farmers in their day-to-day tasks in an easy, cost-effective way. We do this by creating a complete ecosystem for seamless communication between farmers, data aggregation for real time analytics and advice, farm management tools for tracking capital and warehouse access for quick replenishment of input goods. In addition, we are continuously creating new ways for farmers to get into smart farming like IoT enabled sensors and tools to measure metrics like soil health, humidity, and temperature at various nodes of the farm. </p>
                                    </div>
                                    <div className="st-height-b25 st-height-lg-b25"></div>
                                    <div className="st-text-block-avatar">
                                        <div className="st-avatar-img"></div>
                                        <div className="st-avatar-info">
                                        
                                            <div className="st-avatar-designation"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="st-height-b0 st-height-lg-b30"></div>
                    </div>
                  
                    <div className="col-lg-6 wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0.2s">
                        <div className="st-about-img">
                            <div className="st-about-img-in st-dynamic-bg st-bg" style={{ backgroundImage: "url(/assets/img/bg-2.jpg)" }} data-src="assets/img/bg-2.jpg" alt="Bluella Agritech">
                   
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
            <div className="st-height-b40 st-height-lg-b40"></div>
        </section>
       
    </div>
  )
}
