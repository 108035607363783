import React from 'react'

export default function Contact() {
  return (
    <div className='container'>
        <section className="st-shape-wrap" id="contact">
            <div className="st-shape1"><img src="assets/img/shape/contact-shape1.svg" alt="shape1"/></div>
            <div className="st-shape2"><img src="assets/img/shape/contact-shape2.svg" alt="shape2"/></div>
            <div className="st-height-b20 st-height-lg-b80"></div>
            <div className="container">
                <div className="st-section-heading st-style1">
                    <h2 className="st-section-heading-title">Get in touch with us</h2>
                      <div className="st-height-b40 st-height-lg-b40"></div>
                    <div className="st-section-heading-subtitle">To know more about Agritech or if you have a project to discuss, please fill the below form.</div>
                </div>
                <div className="st-height-b40 st-height-lg-b40"></div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div id="st-alert"></div>
                     
                        <form action="send.php" className="row st-contact-form st-type1" method="post" >
                            <div className="col-lg-6">
                                <div className="st-form-field st-style1">
                                    <label>First Name *</label>
                                    <input type="text" id="name" name="fname" placeholder="First Name" required />
                                </div>
                            </div>
                          
                            <div className="col-lg-6">
                                <div className="st-form-field st-style1">
                                    <label>Last Name</label>
                                    <input type="text" id="subject" name="lname" placeholder="Last Name" />
                                </div>
                            </div>
                           
                            <div className="col-lg-6">
                                <div className="st-form-field st-style1">
                                    <label>Email Address</label>
                                    <input type="email" id="email" name="email" placeholder="Email Id" />
                                </div>
                            </div>
                          

                            <div className="col-lg-6">
                                <div className="st-form-field st-style1">
                                    <label>Phone *</label>
                                    <input type="text" id="phone" name="phone" placeholder="Mobile No." required  />
                                </div>
                            </div>
                           
                            <div className="col-lg-12">
                                <div className="st-form-field st-style1">
                                    <label>Your Message</label>
                                    <textarea cols="30" rows="10" id="msg" name="msg"  placeholder="Write something here..." required></textarea>
                                </div>
                            </div>
                         
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <div className="st-height-b10 st-height-lg-b10"></div>
                                    <button className="st-btn st-style1 st-color1 st-size-medium" type="submit" id="submit" name="submit">Send message</button>
                                </div>
                            </div>
                       
                        </form>
                    </div>
                 
                </div>
            </div>
            <div className="st-height-b120 st-height-lg-b80"></div>
        </section>
       
    </div>
  )
}
