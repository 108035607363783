import React from 'react'

export default function Howwedo() {
  return (
    <div>
        <section className="st-gray-bg" id="howwedo">
            <div className="st-height-b20 st-height-lg-b80"></div>
            <div className="container">
                <div className="st-section-heading st-style1">
                    <h2 className="st-section-heading-title">How we do?</h2>
                   
                </div>
                <div className="st-height-b20 st-height-lg-b40"></div>
            </div>
            <div className="container">
                <div className="st-tabs st-fade-tabs st-style1">

                    <div className="st-height-b20 st-height-lg-b25"></div>
                    <div className="tab-content">
                        <div id="Crutches" className="st-tab active">
                            <div className="st-imagebox st-style2">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="st-imagebox-img"><img src="assets/img/iot-3.png" alt="Bluella Agritech"/></div>
                                        <div className="st-height-b0 st-height-lg-b30"></div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="st-vertical-middle">
                                            <div className="st-vertical-middle-in">
                                                <div className="st-imagebox-info">
												
                                                    <ul className="fa-ul">
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span> Increasing agricultural output with IoT and AI.</li>
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span> Giving farmers access to data and tools.</li>
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Farmer develops, and Bluella assists with technology and sales. </li>
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Current weather and weather forecasts.</li>
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Offer timely insights and direction to assist farmers in making crucial decisions.</li>
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Enhance a farmer's crop yield by 5 to 10% and income by up to 30% with the use of a cloud-based analytics system. Keep track of the soil's temperature, moisture level, and nutrient concentration.</li>
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Analytical modelling.</li>
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Obtain data for the company even if they are not an expert in technology.</li>
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Up-to-date information regarding farms and crops.</li>
                                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Crop diseases, pests, live weather, and automated warnings and updates about crop conditions.</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            <div className="st-height-b20 st-height-lg-b80"></div>
        </section>
       
    </div>
  )
}
