import React from 'react'

export default function Footer() {
  return (
    <div>
        <footer className="st-site-footer st-sticky-footer st-dynamic-bg" data-src="assets/img/footer-bg.png">
        <div className="st-main-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="st-footer-widget">
                            <div className="st-text-field">
                                <img src="assets/img/footer-logo.png" alt="Bluella Agritech" className="st-footer-logo"/>
                                <div className="st-height-b25 st-height-lg-b25"></div>
                                <div className="st-footer-text">Bluella Agritech is a digital agriculture company that is changing the lives of farmers in India. We are developing hardware, software, and services to address challenges farmers and processors pose for each other - at every stage of the agricultural value chain. We believe.</div>
                                <div className="st-height-b25 st-height-lg-b25"></div>
                                <ul className="st-social-btn st-style1 st-mp0">
                                    <li><a href="https://www.facebook.com/bluella.agritech" target="_blank" rel="noreferrer"><i className="fab fa-facebook-square"></i></a></li>
                                    <li><a href="/"><i className="fab fa-linkedin"></i></a></li>
                                    <li><a href="/"><i className="fab fa-youtube-square"></i></a></li>
                                    <li><a href="/"><i className="fa-brands fa-square-google-plus"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-lg-4">
                        <div className="st-footer-widget">
                            <h2 className="st-footer-widget-title">Site Links</h2>
                            <ul className="st-footer-widget-nav st-mp0">
                                <li><a href="#home"><i className="fas fa-chevron-right"></i>Home</a></li>
                                <li><a href="#whatwedo"><i className="fas fa-chevron-right"></i>What we do</a></li>
                                <li><a href="#howwedo"><i className="fas fa-chevron-right"></i>How we do</a></li>
                                <li><a href="#whychoose"><i className="fas fa-chevron-right"></i>Why choose us?</a></li>
                                <li><a href="#about"><i className="fas fa-chevron-right"></i>About us</a></li>
                                <li><a href="#contact"><i className="fas fa-chevron-right"></i>Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                                        
                 
                    <div className="col-lg-4">
                        <div className="st-footer-widget">
                            <h2 className="st-footer-widget-title">Contacts</h2>
                            <ul className="st-footer-contact-list st-mp0">
                                <li><span className="st-footer-contact-title">Email:</span> <a href="mail:agritech@bluella.in">agritech@bluella.in</a></li>
                                <li><span className="st-footer-contact-title">Phone:</span><a href="tel:+919810117716">+91.981-011-7716</a></li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        <div className="st-copyright-wrap">
            <div className="container">
                <div className="st-copyright-in">
                    <div className="st-left-copyright">
                        <div className="st-copyright-text"> Copyright ©
                            <script type="text/javascript">
                                document.write(new Date().getFullYear());
                            </script> Bluella Private Limited. All rights reserved.
                        </div>
                    </div>
                    <div className="st-right-copyright">
                        <div id="st-backtotop"><i className="fas fa-angle-up"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    
    </div>
  )
}
