import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Hero from './components/Hero';
import Howwedo from './components/Howwedo';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import Overview from './components/Overview';
import Whatwedo from './components/Whatwedo';
import Whychoose from './components/Whychoose';

function App() {
  return (
    <>
      <Navbar />
      <div className="st-content">
        <div className="st-height-b125 st-height-lg-b80" id="home"></div>
        <Hero/>
        <Overview/>
        <Whatwedo/>
         <Howwedo/>
        <Whychoose/>
        <About/>
        <Gallery/>
       <Newsletter/>
        <Contact />
        </div>
      <Footer/>
    </>
  );
}

export default App;
