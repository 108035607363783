import React from 'react'

export default function Navbar() {
  return (
    <div className="container">
       
    <header className="st-site-header st-style1 st-sticky-header">
        <div className="st-top-header">
            <div className="container">
                <div className="st-top-header-in">
                    <ul className="st-top-header-list">
                        <li>
                        <i className="fab fa-envelope"></i><a href="/">agritech@bluella.in</a></li>
                        <li>
                          <i className="fab fa-phone"></i><a href="tel:+919810117716">+91.981-011-7716</a></li>
                    </ul>
                    <ul className="st-social-btn st-style1 st-mp0">
                        <li><a href="https://www.facebook.com/bluella.agritech" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                        <li><a href="/"><i className="fab fa-youtube"></i></a></li>
                        <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="st-main-header">
            <div className="container">
                <div className="st-main-header-in">
                    <div className="st-main-header-left">
                        <a className="st-site-branding" href="/"><img src="assets/img/logo.png" alt="BluellaAgritech Logo"/></a>
                    </div>
                    <div className="st-main-header-right">
                        <div className="st-nav">
                            <ul className="st-nav-list st-onepage-nav">
                                <li><a href="#home" className="st-smooth-move">Home</a></li>

                                <li><a href="#whatwedo" className="st-smooth-move">What we do
</a></li>
                                <li><a href="#howwedo" className="st-smooth-move"> How we do
</a></li>
                                <li><a href="#whychoose" className="st-smooth-move">Why choose us?
</a></li>
                                <li><a href="#about" className="st-smooth-move">About us
</a></li>
                                <li><a href="#gallery" className="st-smooth-move">Gallery
</a></li>

                                <li><a href="#contact" className="st-smooth-move">Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    
    </div>
  )
}
