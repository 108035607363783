import React from 'react'

export default function Gallery() {
  return (
    <div>
         <section id="gallery">
            <div className="st-height-b20 st-height-lg-b80"></div>
            <div className="container">
                <div className="st-section-heading st-style1">
                    <h2 className="st-section-heading-title">View our gallery</h2>
                       <div className="st-height-b40 st-height-lg-b40"></div>
                </div>
                <div className="st-height-b40 st-height-lg-b40"></div>
            </div>
            <div className="container">
                <div className="st-portfolio-wrapper">

                    <div className="st-isotop st-style1 st-port-col-3 st-has-gutter st-lightgallery">
                        <div className="st-grid-sizer"></div>
                        <div className="st-isotop-item cardiology urology">
                            <a href="assets/img/gallery/p1.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p1.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
                  

                        <div className="st-isotop-item cardiology neurology">
                            <a href="assets/img/gallery/p2.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p2.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
                    

                        <div className="st-isotop-item urology pulmonary">
                            <a href="assets/img/gallery/p3.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p3.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
                     

                        <div className="st-isotop-item neurology traumatology">
                            <a href="assets/img/gallery/v1.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/v1.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
                     

                        <div className="st-isotop-item cardiology pulmonary">
                            <a href="assets/img/gallery/p4.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p4.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
                     

                        <div className="st-isotop-item neurology traumatology">
                            <a href="assets/img/gallery/p6.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p6.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
                       

                        <div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p7.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p7.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						  <div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p8.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p8.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						     <div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p9.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p9.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						  <div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p10.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p10.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						
						   <div className="st-isotop-item neurology traumatology">
                            <a href="assets/img/gallery/v2.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/v2.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						
						
						
							     <div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p11.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p11.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						 

						 <div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p12.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p12.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						
						  <div className="st-isotop-item neurology traumatology">
                            <a href="assets/img/gallery/v3.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/v3.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
							     
								 
								 <div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p13.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p13.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						
						<div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p14.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p14.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
					
					<div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p15.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p15.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						
						<div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p16.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p16.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
						
						
						
                       
					
							     <div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p17.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p17.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
					
				
		    <div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/v8.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/v8.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>

				<div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p18.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p18.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
				
				<div className="st-isotop-item urology pulmonary traumatology">
                            <a href="assets/img/gallery/p19.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p19.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
		
		
		<div className="st-isotop-item neurology traumatology">
                            <a href="assets/img/gallery/21.png" className="st-project st-zoom st-lightbox-item st-link-hover-wrap">
                                <div className="st-project-img st-zoom-in"><img src="assets/img/gallery/p21.png" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli"/></div>
                                <span className="st-link-hover"><i className="fas fa-arrows-alt"></i></span>
                            </a>
                        </div>
			
			    
				
                  
				  
				  </div>
                   
                </div>
            </div>
            <div className="st-height-b120 st-height-lg-b80"></div>
        </section>
       
    </div>
  )
}
