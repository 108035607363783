import React from 'react'

export default function Newsletter() {
  return (
    <div className='container'>
              <section className="st-gray-bg st-news-letter-section  st-bg" data-src="assets/img/news-letter-bg.png">
            <div className="st-height-b20 st-height-lg-b80"></div>
            <div className="container">
                <div className="st-section-heading st-style1">
                    <h2 className="st-section-heading-title">Subscribe & stay updated</h2>
                       <div className="st-height-b40 st-height-lg-b40"></div>
                    <div className="st-section-heading-subtitle">Get the best update. Right in your inbox!  We hate spam and promise to keep your email address safe.</div>
                </div>
                <div className="st-height-b40 st-height-lg-b40"></div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="st-news-letter">
                            <form className="mailchimp st-news-letter-form" action="">
                                <input type="email" name="subscribe" id="subscriber-email" placeholder="Enter Your Email Address"/>
                                <button type="submit" id="subscribe-button" className="st-mailchimp-btn"><i className="fas fa-paper-plane"></i></button>
                             
                                <h5 className="subscription-success"> .</h5>
                                <h5 className="subscription-error"> .</h5>
                                <label className="subscription-label" htmlFor="subscriber-email"></label>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="st-height-b40 st-height-lg-b80"></div>
        </section>
    </div>
  )
}
