import React from 'react'

export default function Hero() {
  return (
    <div>
          <div className="st-hero-wrap st-color1 st-bg st-dynamic-bg overflow-hidden" style={{ backgroundImage: "url(/assets/img/hero-bg11.jpg)" }} data-src="assets/img/hero-bg11.jpg" alt="Bluella Agritech Chinchpada Dadra Nagar Haveli">
            <div className="st-hero st-style1 st-type1">
                <div className="container">
                    <div className="st-hero-text">
                        <h3 className="st-hero-title">Developing Technologies for the next Agri-Revolution</h3>
                        <div className="st-hero-subtitle">The next agri-revolution will bring about new technologies that will help farmers increase yields, decrease inputs, and improve sustainability.</div>
                        <div className="st-hero-btn">
                            <a href="#howwedo" className="st-btn st-style1 st-color3 st-smooth-move">Learn More</a>
                        </div>
                    </div>
                    <form method="POST" action="send.php" className="st-appointment-form">
                        <div className="st-wave-animation st-white"></div>
                        <h2 className="st-appointment-form-title text-center">Contact Us</h2>
                        <div id="st-alert1"></div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="st-form-field st-style1">
                                    <input type="text" id="uname" name="uname" placeholder="First Name *" required maxlength="30"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="st-form-field st-style1">
                                    <input type="text" id="luname" name="luname" placeholder="Last Name" required maxlength="30"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="st-form-field st-style1">
                                    <input type="text" id="uemail" name="uemail" placeholder="Email Id" required maxlength="30"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="st-form-field st-style1">
                                    <input type="text" id="unumber" name="unumber" placeholder="Mobile No.*" required  onkeypress="return isNumber(event)"  maxlength="10"/>
                                </div>
                            </div>


                            <div className="col-lg-12">
                                <div className="st-form-field st-style1">
                                    <textarea cols="30" rows="10" id="umsg" name="umsg" required maxlength="250" placeholder="Write something here..."></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <button className="st-btn st-style1 st-color1 st-size-medium w-100" type="submit" id="appointment-submit" name="appointment-submit">Send message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
  )
}
