import React from 'react'

export default function Whychoose() {
  return (
    <div>
         <section id="whychoose">
            <div className="st-height-b20 st-height-lg-b80"></div>
            <div className="container">
                <div className="st-section-heading st-style1">
                    <h2 className="st-section-heading-title">Why choose us?</h2>
                      <div className="st-height-b40 st-height-lg-b40"></div>
                </div>
                <div className="st-height-b20 st-height-lg-b40"></div>
            </div>
            <div className="container">
                <div className="row">

                    <ul className="fa-ul">
                        <li> <span className="fa-li"><i className="fa fa-check"></i></span> Farmers may determine the finest methods on their farms with the assistance of Bluella Agritech as a technology partner.</li>
                        <li> <span className="fa-li"><i className="fa fa-check"></i></span> Bluella Agritech, helps farmers take precise decisions and increase farm productivity with the help of real-time farm data. This helps them to increase their income while reducing costs.</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>  Bluella's data-driven approach to agriculture management helps farmers identify the best practices on their farms. It's a great tool for optimizing fertilizer usage, managing crop cycles, and identifying growth patterns. </li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span> Our sensor controllers feature a built-in data gateway to support Internet of Things devices and enable remote management.</li>

                    </ul>


                </div>
            </div>
            <div className="st-height-b20 st-height-lg-b80"></div>
			 <div className="st-height-b40 st-height-lg-b80"></div>
        </section>
      
    </div>
  )
}
